import React from "react";
import Logo from "../Assests/Images/logomain.png";

export const Rulesforcom = () => {
  return (
    <div className="h-screen text-center ">
      <div className="flex  items-center justify-center ">
        <a href="" className="py-3.5 px-4  self-center ">
          <img src={Logo} alt="logo" className="h-25 w-12" />
        </a>
      </div>

      <h3 className="text-2xl text-black mt-10  font-bold">
        Rules for competition
      </h3>
      <p className="text-justify py-3.5 px-8">
        <br /> (i) Participants can only showcase their talent and upload their
        own video content.
        <br />
        (ii) Uploaded challenge videos should be related to selected #Hashtag
        categories.
        <br />
        (iii) The Participant’s face should be visible in the video while
        performing.
        <br />
        (iv) After 6 hours’ winner will be declared on the basis of maximum
        votes.
        <br />
        (v) The rewards will be split between winner and runner-up in the ratio
        of their votes, based on the appreciation of the gifts, earned from
        users.
        <br />
        (vi) Users can express their views in the form of votes.
      </p>

      <p className="text-justify py-3.5 px-8">
        <h4 className="text-black font-bold ">How to Create challenge? </h4>
        Open Camera (icon) > Create/Upload Video > Select #Hashtag > Select one
        or more challengers > click on challenge.
        <br />
        <h4 className="text-black font-bold ">How to Accept challenge? </h4>
        Open Notification (icon) > Challenge Notification > Click on View >
        Accept Challenge/Post challenge > Create/Upload Video.
        <br />
        <h4 className="text-black font-bold ">How to Challenge Back?</h4>
        Home screen/Explore Screen/Other user Profile > Click on challenge
        button > Create/Upload Video >
        <br />
        <h4 className="text-black font-bold ">How to vote?</h4>
        Home screen > Shield crown (icon) > Hold it > Slide up for above
        participant/ Slide Down for Below Participant.
        <br />
        <h4 className="text-black font-bold ">How to Appreciate Talent? </h4>
        Gifts are used to appreciate others’ talent/passion/creativity. You can
        encourage, motivate the participants by sending gifts. Your name will be
        reflected in the notifications of participants.
        <br />
        <h4 className="text-black font-bold ">
          What if I don’t follow the competition rules?
        </h4>
        Your account will be deactivated or Removed from Or Platform.
        <br />
        <h4 className="text-black font-bold ">
          *Note: For more guidelines, Refer Terms and Conditions, Privacy
          policies.{" "}
        </h4>
      </p>
    </div>
  );
};
