import React, { useEffect } from "react";
import { AboutSection } from "../component/AboutSection";
import { BannerSection } from "../component/BannerSection";
import { CTAsection } from "../component/CTAsection";
import { FooterSection } from "../component/FooterSection";
import { Header } from "../component/Header";
import { HeroSection } from "../component/HeroSection";
import { Introoverlay } from "../component/Introoverlay";
import { gsap } from "gsap";

export const HomePage = () => {
  useEffect(() => {
    const tl = gsap.timeline();
    tl.from(".heading__title", 0, {
      // y: -500,
      // ease: "out",
      // delay: 1,
      // css: { color: "#fff" },
      // stagger: {
      //   amount: 0.3,
      // },
    })
      .to(".overlay-top", 1.3, {
        height: 0,
        ease: "expo.inOut",
        stagger: 0.4,
      })
      .to(".intro-overlay", 0, {
        css: { display: "none" },
      });
  });
  return (
    <div className="">
      <Introoverlay />
      <div className="h-full bg-black">
        <Header />
        <HeroSection />
        <AboutSection />
        <BannerSection />
        <CTAsection />

        <FooterSection />
      </div>
    </div>
  );
};

//sm
//sm:640px
//md:768px
//lg:1024px
//xl:1280px
//2xl:1536px
