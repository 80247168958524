import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HomePage } from "./Pages/HomePage";
import { RegisterPage } from "./Pages/RegisterPage";
import { Termsandcondition } from "./Pages/Termsandcondition";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { Faq } from "./Pages/Faq";
import { Rulesforcom } from "./Pages/Rulesforcom";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/Register" component={RegisterPage} />
          <Route
            exact
            path="/Termsandcondition"
            component={Termsandcondition}
          />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/FAQ's" component={Faq} />
          <Route exact path="/RulesforCompetition" component={Rulesforcom} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
