import React from "react";
import { useHistory } from "react-router-dom";
import { GradientCom } from "./GradientCom";

export const CTAsection = () => {
  const history = useHistory();
  const handler = () => {
    history.push("/Register");
  };
  return (
    <div className="mb-18 ">
      <div className=" py-3.5 px-3.5 text-center ">
        <h1 className="text-5xl tracking-wide uppercase  text-transparent bg-clip-text bg-gradient-to-r from-secondary to-tertiary  font-rubik leading-tighter font-extrabold mt-4 xl:text-7xl xl:tracking-normal ">
          Want <br /> to get <br />{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-b  from-red  to-black1">
            Recognize
          </span>{" "}
          <br /> by your <br /> Creativity ?
        </h1>
        <h3 className="mt-5 text-green-400 border-l text-2l xl:text-2xl ">
          Get the early Access Now!
        </h3>

        <GradientCom />

        {/* <div onClick={handler} className="mt-10 xl:mt-14  ">
          <button className="relative px-10 py-3 font-medium text-white group  xl:font-bold ">
            <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-purple-500 group-hover:bg-purple-700 group-hover:skew-x-12"></span>
            <span className="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-purple-700 group-hover:bg-purple-500 group-hover:-skew-x-12"></span>

            <span className="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-purple-600 -rotate-12"></span>
            <span className="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-purple-400 -rotate-12"></span>
                   
          
            <span className="relative text-2xl font-bold  ">Get Link!</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};
