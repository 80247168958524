import React, { useEffect, useRef } from "react";
import Mob1 from "../Assests/Images/Or-mockupfinal.png";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { GradientCom } from "./GradientCom";
import { useHistory } from "react-router-dom";

export const HeroSection = () => {
  //useHistory hook

  const history = useHistory();

  const handler = () => {
    history.push("/Register");
  };

  //refs

  const phoneref = useRef(null);

  useEffect(() => {
    //registering the Plugin
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  useEffect(() => {
    function intro() {
      const tl = gsap.timeline();
      tl.from(
        phoneref.current,
        { y: 200 },
        { y: 0, duration: 2, ease: "inOut" }
      );
      return tl;
    }
    function stoptrigger() {
      //base Animations
      // const tl = gsap
      //   .timeline({
      //     delay: 1,
      //     scrollTrigger: {
      //       trigger: phoneref.current,
      //       start: "top top",
      //       end: "+=750",
      //       //end: "+=1150",
      //       pin: true,
      //       scrub: true,
      //     },
      //   })
      //   .to(
      //     ".imagecontainer-phone__img",
      //     {
      //       height: 355,
      //       scale: 0.9,
      //       ease: "easeOut",
      //       // backgroundColor: "white",
      //       // height: 350,
      //       // scale: 1,
      //       // ease: "easeOut",
      //       // backgroundColor: "white",
      //     },
      //     "+=0.51"
      //   )
      //   .to(
      //     ".hidephoneimage",
      //     {
      //       opacity: 0,
      //       ease: "none",
      //     },
      //     "+=0.2"
      //   );
      // return tl;
      //768px
      ScrollTrigger.matchMedia({
        "(min-width: 200px) and (max-width: 620px)": function () {
          const tl = gsap
            .timeline({
              delay: 1,
              scrollTrigger: {
                trigger: phoneref.current,
                start: "top top",
                end: "+=750",
                pin: true,
                scrub: true,
              },
            })
            .to(
              ".imagecontainer-phone__img",
              {
                height: 355,
                scale: 0.9,
                ease: "easeOut",
              },
              "+=0.86"
            );

          return tl;
        },
      });
      //upto 640px
      ScrollTrigger.matchMedia({
        "(min-width: 641px) and (max-width: 770px)": function () {
          const tl = gsap
            .timeline({
              delay: 1,
              scrollTrigger: {
                trigger: phoneref.current,
                start: "mid",
                end: "+=920",
                pin: true,
                scrub: true,
              },
            })
            .to(
              ".imagecontainer-phone__img",
              {
                height: 355,
                scale: 1,
                ease: "easeOut",
                //backgroundColor: "white",
              },
              "+=0.86"
            );

          return tl;
        },
      });

      ScrollTrigger.matchMedia({
        "(min-width: 771px) and (max-width: 1440px)": function () {
          const tl = gsap
            .timeline({
              delay: 1,
              scrollTrigger: {
                trigger: phoneref.current,
                start: "top",
                end: "+=600",
                pin: true,
                scrub: true,
              },
            })
            .to(
              ".imagecontainer-phone__img",
              {
                height: 500,

                ease: "easeOut",
                //backgroundColor: "white",
              },
              "+=0.58"
            );

          return tl;
        },
      });

      ScrollTrigger.matchMedia({
        "(min-width: 1441px) and (max-width: 2560px)": function () {
          const tl = gsap
            .timeline({
              delay: 1,
              scrollTrigger: {
                trigger: phoneref.current,
                start: "top",
                end: "+=750",
                pin: true,
                scrub: true,
              },
            })
            .to(
              ".imagecontainer-phone__img",
              {
                height: 500,

                ease: "easeOut",
                //backgroundColor: "white",
              },
              "+=0.58"
            );

          return tl;
        },
      });
    }

    const master = gsap.timeline();
    master.add(intro());
    setTimeout(() => {
      master.add(stoptrigger());
    }, 500);
  });

  return (
    <section className="h-full -z-10    ">
      <div className="py-3.5 px-3.5   xl:px-1  xl:flex xl:flex-row xl:py-0.5    xl:h-screen ">
        <div className=" text-center font-bold h-full  xl:ml-18  ">
          <h1 className="text-7xl  text-transparent bg-clip-text bg-gradient-to-b  from-red  to-black1  sm:text-8xl  ">
            Or
          </h1>
          <h1 className="text-3xl mit  text-white mt-6 font-bold uppercase sm:text-4xl ">
            World's 1<sup className="lowercase">st</sup>
          </h1>
          <h1 className="text-5xl tracking-tight text-transparent bg-clip-text uppercase bg-gradient-to-r from-secondary to-tertiary  font-rubik leading-tighter font-extrabold mt-4  sm:text-7xl  xl:text-7xl xl:font-rubik  ">
            Online Competition Platform
          </h1>

          <GradientCom onClick={handler} />
        </div>
        <div className="flex flex-col items-center mt-8  xl:w-8/12  ">
          <div
            ref={phoneref}
            className="imagecontainer-phone__img xl:w-5/12  xl:mr-48 "
          >
            <img
              src={Mob1}
              alt="hero"
              className=" md:w-11/12  sm:max-h-full  sm:w-full mt-10   "
            />
          </div>
        </div>
      </div>
    </section>
  );
};
