import React from "react";
import AppleLogo from "../Assests/Images/APPStorelogo.png";
import Androidlogo from "../Assests/Images/PLAYSTORELOGO.png";

export const GradientCom = ({ onClick }) => {
  return (
    <div className="px-5 py-15 mt-8  sm:px-3 sm:py-3.5   ">
      <div className="grid gap-8 items-start justify-center  ">
        <div className="relative group">
          <div className="absolute -inset-0.5 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur opacity-100 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
          <button className="relative px-7  bg-black rounded-lg leading-none flex items-center divide-x divide-gray-600">
            <span className="flex items-center space-x-5">
              <span className="pr-6 text-gray-100  font-bold  sm:text-1xl  xl:text-xl py-2.5 ">
                <a href="https://apps.apple.com/in/app/or-the-competition-platform/id1592103534">
                  <img src={AppleLogo} className="h-15 w-40 cursor-pointer" />
                </a>
              </span>
            </span>
            <span className="pl-6 text-indigo-400 group-hover:text-gray-100  font-bold transition duration-200 sm:text-2xl xl:text-xl ">
              <a href="https://play.google.com/store/apps/details?id=com.ocer">
                <img src={Androidlogo} className="h-15 w-40 cursor-pointer" />
              </a>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

//implemented the Play store and Apple Store logo
