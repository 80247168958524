import React from "react";
import Logo from "../Assests/Images/logomain.png";

export const PrivacyPolicy = () => {
  return (
    <div className="h-screen text-center ">
      <div className="flex  items-center justify-center ">
        <a href="" className="py-3.5 px-4  self-center ">
          <img src={Logo} alt="logo" className="h-25 w-12" />
        </a>
      </div>
      <h2 className="text-2xl text-black mt-10  font-bold">PRIVACY POLICY</h2>
      <p className="text-justify py-3.5 px-8">
        1.GENERAL Last updated on: 2nd Sep, 2021 1.1 Ocre8 Technologies Pvt
        Limited (“OR Competition”, “Ocre8” “company” “We”, “Our”, “Us”) is
        committed to the protection of user “You”,“Your”,“User”) provided
        information which personally identifies You (“Personal (Information”) to
        Ocre8 for the “OR” mobile application/ website. You agree that Your use
        of “ÖR competition” mobile application (“App”)/ Website implies Your
        consent to collecting, receiving, possessing, storing, dealing or
        handling of Your Personal Information including PI (as defined
        hereinafter) by Us in accordance with the terms of this Privacy Policy
        (“Privacy Policy”). 1.2 We take the privacy of our Users seriously. We
        are committed to safeguarding the privacy of Our Users while providing a
        personalized and valuable service. This Privacy Policy applies to all
        Users who access the App and are therefore required to read and
        understand the Privacy Policy before submitting any Personal
        Information. 1 .3 Access to the contents available through the
        App/Website is conditional upon Your approval of this Privacy Policy
        which is in addition to the terms and conditions of use (“Terms”). Any
        term used but not defined in this Privacy Policy will have the same
        meaning as provide in the Terms. You acknowledge that this Privacy
        Policy, together with our Terms, forms Our agreement with You in
        relation to Your use of the App.
      </p>
      <p className="text-justify py-3.5 px-8">
        2.INFORMATION COLLECTED 2.1 Traffic Data Collected In order to provide
        the App/Website, We automatically track and collect the following
        categories of information when You use the App: (i) IP addresses; (ii)
        Domain servers; iii) Other information with respect to Your device,
        interaction of Your device with the App and applications (collectively
        "Traffic Data”). 2.2 Personal Information Collected Personal Information
        collected by Us includes the following categories of information: (i)
        Contact data (such as Your e-mail address, phone number and any extra
        contact details); Device data including device; and (ii) Demographic
        data. (iii) Ocre8 may access your device’s camera, microphone, photo
        and/or video and/or audio library or gallery. (iv) Usage and Log
        Information. We collect service-related, diagnostic, and performance
        information. This includes information about your activity (such as how
        you use Ocre8, how you interact with others using Ocre8, etc.), log
        files, and diagnostics, crash information, website, and performance logs
        and reports, etc. 2.4 Our App may contain links to third party websites
        or applications. The inclusion or exclusion of the link does not imply
        any endorsement by Ocre8 of the website, the website's provider, or the
        information on the website. You agree and understand that privacy
        policies of these websites are not under Our control. Ocre8 does not
        make any representations concerning the privacy practices or policies of
        such third parties or terms of use of such websites, nor does Ocre8
        guarantee the accuracy, integrity, or quality of the information, data,
        text, software, sound, photographs, graphics, videos, messages or other
        materials available on such websites. You understand that once You leave
        Our servers, use of any information You provide shall be governed by the
        privacy policy of the operator of the site used by you. Hence, Ocre8
        encourages the User to read the privacy policies of each such website
        and the User understands that its solely such third party who is
        responsible to the User in this regard.
      </p>
      <p className="text-justify py-3.5 px-8">
        3.USE AND PURPOSE OF COLLECTING PERSONAL INFORMATION INCLUDING PI 3.1
        The information collected from you including PI is used to ensure
        services with respect to the App/Website are presented to You in the
        most effective manner, to carry out our obligations to You, and to
        communicate with You. The said communication can either be by calls,
        text or emails and for purposes which include transactional, service, or
        promotional calls or messages. 3.2 In general, We use Our best efforts
        to use, disclose or transfer information in aggregate form (so that no
        individual user is identified). However, we may use your Personal
        Information including PI for the following purposes: (i) To build up
        marketing profiles; (ii) To aid strategic development, data collection
        and business analytics; To manage our relationship with advertisers and
        partners; (iii) (iv) To enable us to provide the App through the use of
        appropriate technological services; To moderate content on the App in
        accordance with the terms of the relevant policies; To audit usage of
        the App; and (v) To enhance user experience in relation to the App
        (“collectively, “Permitted Use”).
      </p>
      <p className="text-justify py-3.5 px-8">
        4.DISCLOSURE, STORAGE AND TRANSFER OF PERSONAL INFORMATION 4.1 We do not
        disclose or transfer Your Personal Information including PI to any third
        parties other than to the Ocre8’s affiliates and third-party service
        providers, who may or may not be located in India. When we disclose
        Personal Information including PI to any of the foregoing third parties,
        we make appropriate arrangements to restrict further disclosure. When we
        transfer Your Personal Information to such affiliates and third parties,
        including Our affiliates and third parties situated globally or in
        India, We enter into appropriate contractual arrangements to ensure that
        they maintain similar levels of data protection as We would. 4.2 No User
        information is rented or sold to any third party. 4.3 Please note that
        information provided by You, including Personal Information, would be
        retained by Us in our servers which may or may not be situated in India,
        as long as necessary for Permitted Use and/or as required by applicable
        laws. However, We will not retain the PI for longer than is required for
        the purposes for which the information may lawfully be used or is
        otherwise required under any other law for the time being in force. 4.4
        In the event of a merger, reorganization, acquisition, joint venture,
        assignment, spin-off, transfer, asset sale, or sale or disposition of
        all or any portion of our business, including in connection with any
        bankruptcy or similar proceedings, We may transfer any and all Personal
        Information including Personal Information to the relevant third party
        with the same rights of access and use. Please note that this may result
        in Your Personal Information including PI being transferred outside
        India. 4.5 Except as otherwise provided in this Privacy Policy, We will
        keep Your Personal Information including PI private and will not share
        it with third parties, unless We believe in good faith that disclosure
        of Your Personal Information including PI or any other information We
        collect about You is necessary for Permitted Use or to: (i) Comply with
        a court order or other legal process under any applicable laws; (ii)
        Protect the rights, property or safety of Ocre8 or another party; (iii)
        Enforce the agreement, including Terms; or (iv) Respond to claims that
        any posting or other content violates the rights of third-parties. 4.6
        By agreeing to the Privacy Policy, You hereby grant Us Your consent to
        sharing Your information, including Personal Information, with third
        parties, including third parties situated outside India, for the
        Permitted Uses and in all instances henceforth in pursuance of Clause 4
        in pursuance of this Privacy Policy.
      </p>
      <p className="text-justify py-3.5 px-8">
        5. SECURITY 5.1 The security of Your Personal Information including PI
        is important to Us. Hence, we maintain a high standard of security for
        our Users. In this regard, we follow all reasonable security practices
        and procedures as required under applicable laws and which is
        commercially reasonable which includes all physical, managerial,
        operational and technical security measures to protect the Personal
        Information including PI submitted to Us against unauthorized access,
        alteration, disclosure, loss, misuse, or destruction, both during
        transmission and once We receive it. These measures vary based on the
        type and sensitivity of the data. 5.2 Although We make best possible
        efforts to store Personal Information including in a secure operating
        environment that is not open to the public, You should understand that
        there is no such thing as complete security, and We do not guarantee
        that there will be no unintended disclosures of Your Personal
        Information. The transmission of information via the internet or
        telephone networks is not completely secure. While We do Our best to
        protect Your information, particularly with respect to protection of
        Your Personal Information, We cannot ensure the security of Your data
        transmitted via the internet, telephone or any other networks. If We
        become aware that Your Personal Information including SPDI has been
        disclosed in a manner not in accordance with this Privacy Policy, We
        will use reasonable efforts to notify You of the nature and extent of
        such disclosure (to the extent We know that information) as soon as
        reasonably possible and as permitted by law. 5.3 You should not share
        Your password with any third party, and if You believe your password or
        account has been compromised, You should change it immediately and
        contact Us at info@Ocre8.com.
      </p>
      <p className="text-justify py-3.5 px-8">
        6. UPDATES AND CHANGES TO PRIVACY POLICY 6.1 Please note that this is a
        beta version of the App/Website and there may be changes to this Privacy
        Policy during future releases of the App, or even otherwise. We reserve
        the right, at any time, to add to, change, update, or modify this
        Privacy Policy, so please review it frequently. If We make any changes
        to this Privacy Policy, then We will post these changes on this page and
        will inform you of the same. Your continued use of the services
        following the posting of changes to this Privacy Policy will constitute
        Your consent and acceptance of Our changes. 6.2 In all cases, use of
        information We collect is subject to the Privacy Policy in effect at the
        time such information is collected.
      </p>
      <p className="text-justify py-3.5 px-8">
        7.YOUR RIGHTS 7.1 You have a right to review your Personal Information
        and correct any errors in Your Personal Information available with Us by
        writing to us at info@Ocre8.com. 7.2 You may request Us in writing that
        We cease to use Your Personal Information by writing to Us at
        info@Ocre8.com. In the event that you refuse to share any information,
        or withdraw consent to process information that you have previously
        given to us, we reserve the right to restrict or deny the provision of
        our services for which we consider such information to be necessary. 7.3
        If you would like to manage, change, or delete your information, we
        allow you to do that through making a request to our company at
        info@ocre8.com.
      </p>
      <p className="text-justify py-3.5 px-8">
        8.RESTRICTION OF LIABILITY 8.1 We make no claims, promises or guarantees
        about the accuracy, completeness, or adequacy of the contents available
        through the App/ website and expressly disclaims liability for errors
        and omissions in the contents available through the App. 8.2 No warranty
        of any kind, implied, expressed or statutory, including but not limited
        to the warranties of non-infringement of third-party rights, title,
        merchantability, fitness for a particular purpose and freedom from
        computer virus, is given with respect to the contents available through
        the App or its links to other internet resources as may be available to
        Your through the App/Website. 8.3 Reference in the App to any specific
        commercial products, processes, or services, or the use of any trade,
        firm or corporation name is for the information and convenience of the
        company, and does not constitute endorsement, recommendation, or
        favouring by Us. If you have questions or grievances, feel free to
        email, Grievance Officer at info@ocre8.com. and We will attempt to
        address Your concerns.
      </p>
      <p className="text-justify py-3.5 px-8">
        9. DISPUTE RESOLUTION In case of any dispute that cannot be solved
        through negotiation it shall be referred to arbitration for resolution.
        Either party may refer such dispute for final resolution by arbitration
        under the Arbitration and Conciliation Act 1996 and amendment made
        therein time to time. The Tribunal shall consist of three (3)
        arbitrators. Each party shall appoint 1 (one) arbitrator and the two
        arbitrators shall appoint the third arbitrator. The arbitration
        proceedings shall be held at Nagpur, Maharashtra. The language to be
        used in the arbitral proceedings shall be English. Subject to the above,
        the Courts at Nagpur, Maharashtra shall have exclusive jurisdiction
      </p>
    </div>
  );
};
