import React from "react";
import Logo from "../Assests/Images/logomain.png";

export const Faq = () => {
  return (
    <div className="h-screen text-center ">
      <div className="flex  items-center justify-center ">
        <a href="" className="py-3.5 px-4  self-center ">
          <img src={Logo} alt="logo" className="h-25 w-12" />
        </a>
      </div>
      <h2 className="text-2xl text-black mt-10  font-bold">FAQ's</h2>
      <div className="text-justify  py-3.5 px-8">
        <h4 className="text-black font-bold ">How to Create Account?</h4>
        Sign up using Social Login/email, fill all mandatory details.
        <br />
        <h4 className="text-black font-bold ">
          Where will I receive the One Time Password (OTP)?
        </h4>
        You will receive the OTP on the registered email Id with your Or
        account.
        <br />
        <h4 className="text-black font-bold ">
          Why to select your interest category?
        </h4>
        Based on your interest we will suggest your challengers from the similar
        category and show you competitions related to your interest.
        <br />
        <h4 className="text-black font-bold ">What is voting?</h4>
        The non-participant users can engage in the competition posts available
        related to participants’ talent, creativity, and passion on the platform
        and can express their views in the form of a vote on some or every
        competition posts. Final votes on content will decide the best talent or
        passion or creativity.
        <br />
        <h4 className="text-black font-bold ">How to vote?</h4>
        Home screen > Shield crown (icon) > Hold it > Slide up for above
        participant/ Slide Down for Below Participant.
        <br />
        <h4 className="text-black font-bold ">
          How many votes do I have on each competition?{" "}
        </h4>
        Only one vote per competition.
        <br />
        <h4 className="text-black font-bold ">How to Appreciate Talent? </h4>
        Gifts are used to appreciate others’ talent/passion/creativity. You can
        encourage, motivate the participants by sending gifts. Your name will be
        reflected in the participants’ notification screen.
        <br />
        <h4 className="text-black font-bold ">How to purchase gifts emoji? </h4>
        Profile > Settings > Click on Buy more > Select coins > Proceed >
        Preferred Payment methods (UPI/Wallet/Net Banking/Debit card/Credit
        card/Wallet) > Pay > Payment successful.
        <br />
        <h4 className="text-black font-bold ">How to Create challenge? </h4>
        Open Camera (icon) > Create/Upload Video > Select #Hashtag > Select one
        or more challenger > click on challenge.
        <br />
        <h4 className="text-black font-bold ">How to Accept challenge? </h4>
        Open Notification (icon) > Challenge Notification > Click on View >
        Accept Challenge/Post challenge > Create/Upload Video.
        <br />
        <h4 className="text-black font-bold ">How to Challenge Back?</h4>
        Home screen/Explore Screen/Other user Profile > Click on challenge
        button > Create/Upload Video
        <br />
        <h4 className="text-black font-bold ">
          Why Should I use Right #hashtag?
        </h4>
        To get optimum visibility on platform.
        <br />
        <h4 className="text-black font-bold ">
          When will Competition get over?{" "}
        </h4>
        In 6 hours.
        <br />
        <h4 className="text-black font-bold "></h4>
        Winner will declare in explore screen under trending category after 6
        hours.
        <br />
        <h4 className="text-black font-bold ">
          How winner and runner up will get rewards?{" "}
        </h4>
        The rewards will be split between winner and runner-up in the ratio of
        their votes, based on the appreciation of the gifts earned from users.
        <br />
        <h4 className="text-black font-bold ">What are Or coins?</h4>
        Coins is the in-app currency on Or which can be converted to real money.
        <br />
        <h4 className="text-black font-bold ">
          When Or coin redeem option get visible?
        </h4>
        This feature is yet to Introduce. Please stay tuned and we will keep you
        updated
        <br />
        <h4 className="text-black font-bold ">
          Is there a time limit for uploading/capturing video?
        </h4>
        30sec video should be uploaded/captured.
        <br />
        <h4 className="text-black font-bold ">
          How to delete own challenge with other users?{" "}
        </h4>
        You can delete own challenge within first 30 mins from Profile > Running
        Challenge > Click on challenge > Press Delete Option Delete option is
        only visible for first 30 mins after your challenge uploaded. You can
        only delete own video challenge; the competition will get deleted from
        platform if you find something inappropriate like • hashtag is not
        matching with uploaded video
        <br />
        • the video content is not related to talent/passion/creativity <br />
        • somebody else video/content uploaded by user <br />
        • Violence of laws, sexual content.
        <br />
        <h4 className="text-black font-bold ">
          How to report obscene content?
        </h4>
        Please tap on the 3 dots icon over the video and use the report option
        to help us remove such obscene content. Thank you.
        <br />
        <h4 className="text-black font-bold ">
          What if I don’t follow the competition rules?
        </h4>
        Your account will be deactivated or Removed from Or Platform.
        <br />
        <h4 className="text-black font-bold ">
          How to Increase followers count?
        </h4>
        Right hashtag and original video content will help you to increase
        Followers.
        <br />
        <h4 className="text-black font-bold ">
          What are the benefits of competition?{" "}
        </h4>
        Learning from other competitor, Practice on Platform, Performance grow
        your confidence
        <br />
        <h4 className="text-black font-bold ">
          What are the learnings for participants?{" "}
        </h4>
        Improving your Talent, Enhancing the skills, Growth of mindset to
        compete
        <br />
        <h4 className="text-black font-bold ">Why you should participate? </h4>
        To improve your personality
        <br />
        <h4 className="text-black font-bold ">Why your vote is important?</h4>
        Your vote can Engaged, motivate & appreciate user’s talent and
        Creativity
        <br />
        <h4 className="text-black font-bold ">How to report video content? </h4>
        Profile screen > click on (icon) > tap on Report option
        <br />
        <h4 className="text-black font-bold ">
          Why follow rules of competition?
        </h4>
        Otherwise, your account has been deactivated strictly.
        <br />
        <h4 className="text-black font-bold ">Can I change my user name?</h4>
        No
        <br />
        <h4 className="text-black font-bold ">How to receive notification?</h4>
        Please ensure it is not turned off in-app setting Open Or > tap on
        profile > click on setting > turn notification ‘ON’
        <br />
        <h4 className="text-black font-bold ">How to delete my account?</h4>
        You cannot delete your account. Please reach out to us to help you in
        this regards. For any assistance/help email us Thank you
      </div>
    </div>
  );
};
