import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";

export const RegisterPage = () => {
  //Validation

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitMessage, SetsubmitMessage] = useState();

  const onSubmit = (data) => {
    // console.log(data);
    Axios.post("https://orplatform.in/Registration/insert.php", data).then(
      (response) => SetsubmitMessage(response.data.msg)
    );
    // console.log(submitMessage);
  };

  return (
    <div className=" h-full bg-gray-800  ">
      {submitMessage ? (
        <div className=" bg-white  h-screen text-center  flex flex-col justify-center items-center px-4 ">
          <div className="py-3.5 px-3.5 text-2xl font-bold text-center bg-green-400   rounded-2xl">
            <h2 className="">{submitMessage}</h2>
          </div>
        </div>
      ) : (
        <div className="bg-gradient-to-b   from-red  to-black1  bottom-0 h-screen flex flex-col  justify-start items-center  ">
          <div className="p-8 flex1 mb-24  xl:mt-14  xl:w-6/12 xl:items-center xl:flex xl:flex-col xl:justify-center ">
            <div className="px-10  pt-8 pb-8 bg-white rounded-2xl  xl:h-full xl:w-6/12 ">
              <h1 className="text-2xl self-center font-semibold text-gray-900">
                Pre-Registration
              </h1>
              <form
                class="mt-12"
                action=""
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="relative">
                  <input
                    id="Email"
                    type="text"
                    name="Email"
                    class="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
                    placeholder="john@doe.com"
                    {...register("Email", {
                      required: true,
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "This is not a valid email",
                      },
                    })}
                  />
                  {errors.Email && (
                    <span className="text-red-600  text-sm">
                      Email is required
                    </span>
                  )}
                  <label
                    for="Email"
                    class="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    Email address Play-store / App-Store
                  </label>
                </div>
                <div class="mt-10 relative">
                  <input
                    id="Name"
                    type="text"
                    name="name"
                    class="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600"
                    placeholder="Name"
                    {...register("Name", { required: true })}
                  />
                  {errors.Name && (
                    <span className="text-red-600  text-sm">
                      Name is required
                    </span>
                  )}
                  <label
                    for="Name"
                    class="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                  >
                    Name
                  </label>
                </div>

                <div className="mt-14">
                  <label class="block text-sm font-medium text-gray-700">
                    Select Device
                  </label>
                  <div class="mt-1">
                    <select
                      name="Select-Device"
                      id="Select device"
                      className="w-full"
                      {...register("device", { required: true })}
                    >
                      <option value="">Select device</option>
                      <option value="Iphone">Iphone</option>
                      <option value="Android">Android</option>
                    </select>
                  </div>
                  {errors.device && (
                    <span className="text-red-600  text-sm">
                      device is required
                    </span>
                  )}
                </div>
                {/* <div className="mt-10 text-red-600 absolute ">
                <p>{submiterror}</p>
              </div> */}

                <button
                  type="submit"
                  class="mt-20 px-4  py-2 rounded bg-red-500 hover:bg-rose-400 text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-rose-500 focus:ring-opacity-80 cursor-pointer"
                >
                  <h3>Submit</h3>
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
