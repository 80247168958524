import React from "react";
import Logo from "../Assests/Images/logomain.png";

export const Termsandcondition = () => {
  return (
    <div className="h-screen text-center ">
      <div className="flex  items-center justify-center ">
        <a href="" className="py-3.5 px-4  self-center ">
          <img src={Logo} alt="logo" className="h-25 w-12" />
        </a>
      </div>
      <h2 className="text-2xl text-black mt-10  font-bold">
        TERMS AND CONDITIONS AND CONTENT POLICY
      </h2>
      <p className="text-justify py-3.5 px-8">
        Welcome to “Or the Competition Platform", a service provided by Ocre8
        Technologies Private Limited (hereinafter referred to as ("Or-the
        Competition Platform ", “we”, “us”, “our”, “Ocre8 Technologies Private
        Limited” “platform” “Company”).
      </p>
      <p className="text-justify py-3.5 px-8">
        We are happy to make our competition platform available to you. Ocre8
        Technologies is a Digital technology, software development & Talent
        Management company that aims at building a range of platforms which will
        allow people to create and connect, to make competitive content that can
        be rewarded by the viewers. We aim at providing a platform, where people
        can create & share their talents with the intent to grow, learn and
        compete. Our primary Product is a “Or-competition platform” which is one
        of its kinds social networking service that encourages people to
        showcase hidden talent, passion and creativity throughout the world to
        make their own identity. The platform promotes to create and participate
        in a person-to-person hashtag video competition on the platform and the
        platform visitor (non-participants) can choose winner by fair voting
        system. The company's platform features Hashtag challenges, Competition
        videos between two users or Groups, voting system, video search, editing
        tools, video browsing and instant sharing options, enabling users to
        showcase their talent and creativity on social media via videos, Gifts
        and stickers, in app purchasing virtual coins. The videos, clips, images
        on “Or-competition platform” posted by the users is original Content
        created by the users.
      </p>
      <p className="text-justify py-3.5 px-8">
        By accessing, downloading, using our Services, you agree to be bound by
        these terms, and you further explicitly consent to our Privacy Policy
        (Ocre8- Privacy Policy), Or-the Competition Platform. We urge you to
        access, review and familiarize yourself with these Terms periodically.
        Your continued use of the Services, will constitute as your consent and
        agreement to these Terms.
      </p>
      <p className="text-justify py-3.5 px-8">
        In the event where the terms are not agreeable to you, we request you to
        desist from accessing, downloading, using the Services in any manner,
        whatsoever.
      </p>
      <p className="text-justify py-3.5 px-8">
        Your agreement with us includes these Terms and our Privacy Policy
        (“Agreements”). You acknowledge that you have read and understood
        Agreements, and agree to be bound of them before using our platform. If
        you do not agree with (or cannot comply with) Agreements, then you may
        not use the Service, but please let us know by emailing
        info@ocre8technologies.com so we can try to find a solution. These Terms
        apply to all visitors, users, and others who wish to access or use
        Service.
      </p>
      <p className="text-justify py-3.5 px-8">
        Definition: Any reference of the following terms in the document will
        mean as defined hereinbelow: 1. Competition: means a challenge started
        by a user by uploading a content and inviting other user to showcase
        better content in the reference of his video. The engaged users vote and
        poll in these competitions to pick a winner. 2. Rewards: Rewards mean
        virtual gift given to the participants of a competition 3. Participant:
        A user who has started or taken part in any or all challenges of the
        platform 4. Non participant: A user who is not an active participant in
        the challenges of the platform but is watching and voting the
        competition. A Non Participant is also referred as Engaged User 5.
        Voting: means the process of liking or supporting someone on the
        platform as per the platform 6. Content: All videos uploaded on the
        platform related to talent, creativity, passion. and anything that is
        permissible by the laws of India. 7. Emoji/tokens/: Emoji/tokens are
        virtual gift that can be purchased through real currency by the user.
        These emoji/tokens are used by user to give them as reward to the
        participants. 8. OR Coins: means virtual currency issued by the platform
        to its user to purchase virtual gifts rewarding participants.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        USER GUIDELINES:{" "}
      </h3>
      <p className="text-justify py-3.5 px-8"></p>
      <p className="text-justify py-3.5 px-8">
        Access to And Continued Use of Services A. The services of the platform
        can be used only by users who are above the age of 13 years. We have a
        right to terminate your account, wherein the user is found to be
        defaulting on this condition, or where we find that you are barred in
        law from access to our Services. B. You are responsible for your use of
        our Services, and undertake to comply with the applicable laws, and act
        in strict adherence to our Privacy Policy. C. You are responsible for
        safeguarding your account and you agree not to disclose your password to
        any third party. You agree that you will be solely responsible for any
        activities or actions taken under your account, irrespective of whether
        you have authorized such activities or actions. You will immediately
        notify us of any unauthorized use of your password or your account, upon
        becoming aware of any such discrepancy. D. We further reserve the right
        to restrict circulation of any content if said content violates the Or –
        The Competition Platform’s Guidelines. The Company may also suspend or
        terminate your account for such violation with immediate effect without
        any intimation. E. The Company is committed to safeguarding the
        efficiency of the Application. For that reason, we reserve the right to
        make changes to the Application for maintenance at any time. If such
        situations cause an interruption of your Services for a reasonable
        duration, we shall not bear any liability to you and/or to any third
        parties. We will make attempts on a best-efforts basis to let you beware
        of any scheduled maintenance to such extent as is possible. F. You agree
        not to: circumvent, remove, degrade, deactivate, or thwart any of the
        contents of our Service; use any robot, spider, scraper, or other means
        to access our Service. You also agree not to decompile, reverse
        engineer, and disassemble any software or other products or processes
        accessible through our Service. Additionally, you agree not to upload,
        post, e-mail or otherwise send or transmit any material designed to
        interrupt, destroy, or limit the functionality of our Service. We may
        terminate or restrict your use of our Service if you violate these terms
        or engaged in any illegal or fraudulent use of our Service. G. You agree
        not to use the accounts of other Users, disparage other accounts, or
        indulge in any activity which violates the Community Guidelines. H. We
        do not claim ownership of your content that you post on or through the
        Service. By submitting, posting, displaying, or communicating Content on
        through your Services, you hereby grant to us a non-exclusive,
        royalty-free, transferable, sub-licensable, worldwide license to host,
        use, distribute, modify, run, copy, reproduce, process, such Content
        across all formats, media now known, or which may come into existence
        later to a reasonable extent You represent and warrant that you have, or
        have obtained, all rights, licenses, necessary authorizations required
        to grant the rights granted herein for any Content that you submit, or
        post, or display, or communicate through our Services. I. We reserve the
        right to update, modify, alter, amend, these Terms at any time, at our
        sole discretion. J. As an aggregator platform all or any of our action
        will not mean that we endorse, support, represent, authorize the
        circulation of all Content published on our platform, and we do not
        further attest to the accuracy, originality, reliability, legitimacy,
        completeness, of such Content, as available on our Services. K. All the
        Content that is available on the Application is the sole responsibility
        of the originator of the Content. As a User, you may come across Content
        which might be offensive, harmful, misleading, inaccurate, or
        inappropriate in any manner construed by the you. We impress upon you
        that we may not monitor or control the Content accessible on the
        Services, and we cannot assume responsibility for such Content. We may
        be required to remove Content, as per the applicable law, and we shall
        abide by such directions as and when they are made. We shall not always
        notify you of such an action taken on our part. L. We give you a
        personal, worldwide, royalty-free, non-assignable and non-exclusive
        license to use the software provided to you as part of the Services. M.
        The Services made available to you are protected by copyright,
        trademark, and other laws. Nothing in these Terms gives you the right to
        use our trademarks, logos, domain names, other distinctive brand
        features, and other proprietary rights. All right, title, and interest
        in and to the Services (excluding Content provided by users) are and
        will remain the exclusive property of the Company and its licensors. N.
        Any feedback, comments, or suggestions you may provide regarding the
        Services is entirely voluntary, and we will be free to use such
        feedback, comments, or suggestions as we see fit and without any
        obligation to you. Only persons who can form legally binding contracts
        under the law of their jurisdiction or those persons that are
        represented by persons who can form legally binding contracts under the
        law of their jurisdiction are permitted to use our Services.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        RULES OF COMPETITIONS
      </h3>
      <p className="text-justify py-3.5 px-8">
        Content & Competition The platform allows all legit users to create
        profiles on the platform. The Users after creating profile are allowed
        to post their content, create competitions, participate in the
        competition and use all features developed and updated on the platform
        by Ocre8 Technologies Private Limited. Competition Post Guidelines Our
        Service allows you to post videos, Profile photos, link, store, share,
        create challenges with other users by selecting hashtags and otherwise
        make available certain information, text, graphics, videos, or other
        material (“Content”). You are solely responsible for Content that you
        post on or through Service, including its legality, reliability, and
        appropriateness. Further you shall be solely responsible for any legal
        action initiated against you due to your content. By posting Content on
        or through Service, you represent and warrant that:{" "}
        <p>
          (i) As a user you can create profile, post content, create
          competition, participate in the competition and use all features of
          the platform made available for its users.
        </p>{" "}
        <br />
        (ii) By participating in the competition mean users have voluntarily
        agreed to create competition or participate in competition and upload
        their content on “Or – The Competition Platform”. <br /> (iii) Users can
        only showcase and upload their posts related to talent, creativity, and
        passion. <br /> (iv) User is creating competition with other user or
        user is participating in the competition, created by another user, in
        both ways a user is agreed to participate by his/her own free will.{" "}
        <br /> (v) The competition is uploaded on “Or – The Competition
        Platform” is content related to user talent, creativity, and passion.{" "}
        <br /> (vi) The non-participant users can engage in the competition
        posts available on the platform and can express their view in the form
        of a vote/poll on some or every competition posts. <br /> (vii) The
        number of final votes/polls on any post will determine the winner of the
        competition. <br /> (viii) As a platform we encourage learning. The
        competitions and content help both users to have the opportunity to
        learn from each other. <br />
        (ix) On the of important rule to participate in the competition post is
        that the user’s face should be shown in content while performing, so
        that the engaged users will be able to recognize that the content is
        original, genuine and real content of the content creator. This will
        enable engaged users to suitably express their views in the form of
        votes/polls. <br /> (x) The Content (should be original) and you should
        own all its rights. (xi) The user shall ensure that the posting of any
        content on or through our Service should not violate the privacy rights,
        publicity rights, copyrights, contract rights, or any other rights of
        any person or entity. We reserve the right to terminate the account of
        anyone found to be infringing on the above ground. (xii) Our platform
        also provides paid emoji/tokens/digital gifts feature for the users of
        the platform. (xiii) The users can purchase paid emojis from the
        platform and can use the same to gift to the participants of the
        competition. The emoji/tokens/digital gifts for all the competition are
        collected in a virtual box. The said collected emoji/tokens/digital
        gifts and then transferred as rewards to the winner and runner up in the
        ratio of votes received the participants of any or all competition
        conducted on the platform. (xiv) The competition can be organised by an
        individual, group or by a third party on the platform. (xv) The winners
        or runner up can convert tokens emoji/tokens/digital gifts into or
        token/ coins which can be redeemed after reaching to point (Minimum
        number of coins) into cash by using our platform. (xvi) You agree that
        your use of the services does not grant you any right to any
        compensation or share in revenue or value. (xvii) To the extent it’s
        necessary, when you generate Content, create competition/challenge or
        accept competition/challenge you also grant us, our affiliates, and our
        business partners the unrestricted, worldwide, perpetual right and
        license to use your name, likeness, voting count, and voice. (xviii) The
        content uploaded or created by you shall not violate applicable law or
        regulation. We are not responsible for any Content posted by you or any
        consequences thereof. (xix) You will not be entitled to any compensation
        from us, our affiliates, or our business partners if your name,
        likeness, or voice is conveyed through the Services, either on the “Or –
        The Competition Platform” application or on one of our business
        partner’s platforms. (xx) Ocre8 Technologies Pvt Ltd has the right but
        not the obligation to monitor and edit all Content provided by users.
        (xxi) In the event the User Content contains any third-party materials
        including brands, trademarks, logos, etc. (“Branded Content”). You are
        required to procure necessary permissions, licenses directly from such
        third parties and will not upload any Branded Content without the same.
        All Branded Content should be tagged/ uploaded as content for paid
        partnerships through the functionality provided by the Platform, failing
        which OCRE8 TECHNOLOGIES shall have the right to takedown the Branded
        Content or remove third party brands, trademarks, logos, etc. from such
        Branded Content, at its sole discretion. In such cases if any third
        party raises any objection our platform will take down your content with
        immediate effect and you will be solely responsible for all legal and
        financial liability arising out of it. (xxii) The quality of the User
        Content may vary from device to device and may be affected by a variety
        of factors, such as Your location, the bandwidth available through,
        and/or speed of Your internet connection. You are responsible for all
        internet access charges. Please check with Your internet provider for
        information on possible Internet data usage charges. (xxiii) You retain
        any of your rights to any Content you submit, post, or display on or
        through Service and you are responsible for protecting those rights. We
        take no responsibility and assume no liability for Content you or any
        third-party posts on or through Service. However, by posting Content
        using Service you grant us the right and license to use, modify,
        publicly perform, publicly display, reproduce, and distribute such
        Content on and through Service. You agree that this license includes the
        right for us to make your Content available to other users of Service,
        who may also use your Content subject to these Terms. Voting Rules (i)
        Once a user, group or a third party creates a competition that invites
        participants to show case their talent, creativity, and passion
        permissible in law the engaged user can vote/poll in the said
        competition based on their choice. (ii) The non-participant users can
        engage in the competition posts available on the platform and can
        express their view in the form of a vote/poll on some or every
        competition posts. (iii) Competition will be conducted for normally 6
        hours only. This duration can be subject to change as per the
        requirements by the platform. All competitions are time bound and they
        come to an end on the completion of time. User can vote only in the
        active competitions. The votes even if given after the closure of the
        competition shall not be counted. (iv) Final votes/polls on content will
        determine the winner and runner up of the competition. It is important
        to note that the platform does have any hold and stance in determining
        the winners. Votes/polls are the sole basis of determining winner. (v)
        Based on voting after 6 hours the result will be declared on explore the
        screen. (vi) The voting bar is for engaged users, who love your video
        content and express their view by voting to only one participant. (vii)
        Competition and content (viii) On the of important rule to participate
        in the competition post is that the user’s face should be shown in
        content while performing, so that the engaged users will be able to
        recognize the genuine and real content of the content creator for
        expressing their views in the form of votes/polls. (ix) We suggest users
        to verify the content and participants on their own before voting or
        polling to encourage fair competition and appreciate original talent.
        (x) Each user has only one vote for one post/ competition . (xi) The
        user with highest votes/ second highest will be announced as winner and
        runner-up through the mechanism set and amended time to time by the
        platform. (xii) The rewards will be given to the winner and runner-up in
        the ratio of their votes. (xiii) In case of tie both the participants
        will be declared as winner and the rewards will be declared in the equal
        ratio. (xiv) In case of draw of votes both the participants/ Teams of
        the competition will be declared as draw and rewards will be divvied
        into 50: 50 ratios. It is made clear that our platform has no separate
        role or control over the competition and we don’t directly or indirectly
        assist any participant nor we influence any one to support any
        particular participant. The competition is real and live. You
        acknowledge and agree that the Content uploaded is the sole
        responsibility of the user who has uploaded such Content and the views
        expressed are their own. “Or – The Competition Platform” is not
        responsible for the Content posted by the Users. You shall be solely
        responsible for the Content posted, shared, modified uploaded,
        transmitted, updated, and hosted by you.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        Rules for uploading valid content
      </h3>
      <p className="text-justify py-3.5 px-8">
        a. Content should be only related to your talent, passion, and
        creativity i.e., dance, singing, gym, or any other widely accepted
        talent which is not against any law. b. Users can Create video through
        other applications by using sounds and filters c. User can Shoot video
        according to the “Or – The Competition Platform” Application, so that
        your content will fit into the screen. You are advised to make good
        content and in good resolution . as user love proper framing videos and
        good quality videos. d. The Uploaded videos can only relate to your
        personal talent, passion, and creativity, and not somebody else's
        talent, passion, and creativity. e. While performing and shooting a
        video, the content creator's face should be visible in the video, so
        that people on the platform will recognize you and vote accordingly. The
        platform holds the right to remove any content where the face of the
        participant is not visible. f. You can select your competitor, who can
        be your friend, stranger by sending request him/her to participate in
        the competition. g. The second Participant has the choice to accept the
        request or decline the request and post the competition. h. Competition
        will be conducted for normally 6 hours only. This duration can be
        subject to change as per the requirements by the platform. All
        competitions are time bound and they come to an end on the completion of
        time. User can vote only in the active competitions. The votes even if
        given after the closure of the competition shall not be counted. i. The
        voting bar is for engaged users, who love your video content and express
        their view by voting to only one participant. j. Based on voting after 6
        hours the result will be declared on explore the screen. k. Engaged
        users can appreciate both participants by sending virtual gifts on the
        platform. l. We strictly advise not to upload videos that include
        people's private information, like a credit card number or phone number,
        and don't upload a photo in profile/ video of anyone who doesn't want
        their video uploaded. Do not post nudity content, inappropriate content.
        m. We strictly advise not to upload Images/ videos of places of high
        security and importance, archaeological sites, railways, defense areas,
        etc. without prior compliance with applicable laws from the concerned
        government authorities and departments. If you see something
        inappropriate, use the "Report Content" option to alert us and we'll
        take care of things.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        LICENSE RESTRICTIONS
      </h3>
      <p className="text-justify py-3.5 px-8">
        Except as expressly set out in these Terms, you agree: (i) Not to copy,
        “Or - The Competition Platform” (including its specific design, user
        interface) except where such copying is incidental to normal use of “Or
        - The Competition Platform”, or where it is necessary for back-up or
        operational security; (ii) Not to make alterations to, or modifications
        of, the whole or any part of “Or - The Competition Platform”, or permit
        the “Or - The Competition Platform” or any part of it to be combined
        with, or become incorporated in, any other programs including any
        rooting software; (iii) not to disassemble, decompile, reverse-engineer,
        or create derivative works based on the whole or any part of the “Or -
        The Competition Platform” or attempt to do any such thing except to the
        extent permitted by applicable law solely because it is essential for
        achieving inter-operability of “Or - The Competition Platform” with
        another software program, and provided that the information obtained by
        you during such activities: a. Used only to achieve inter-operability of
        “Or - The Competition Platform” with another software program; b. Not
        disclosed or communicated without Our prior written consent to any third
        party; c. Is not used to create any software that is substantially
        similar to the “Or - The Competition Platform”. d. Not to provide or
        otherwise make available the “Or - The Competition Platform” in whole or
        in part (including object and source code), in any form to any person
        without prior written consent from us; e. To comply with all technology
        control or export laws and regulations that apply to the technologies
        used or supported by “Or - The Competition Platform” in Your use of the
        “Or - The Competition Platform”. f. To not use the “Or - The Competition
        Platform” in an unlawful manner, for any unlawful purpose, or in any
        manner inconsistent with these Terms, or act fraudulently or
        maliciously, for example, by hacking into or inserting malicious code,
        including viruses, or harmful data, into “Or - The Competition Platform”
        or any operating system and shall at all times remain compliant with
        laws applicable to Your use of “Or - The Competition Platform”; g. Not
        to infringe our intellectual property rights or those of any third party
        or any license terms concerning Your use of “Or - The Competition
        Platform” or any service associated (to the extent that such use is not
        licensed by these Terms); h. Not use the “Or - The Competition Platform”
        in a way that could damage, disable, overburden, impair or compromise
        Our systems or security or interfere with other users; and i. Not
        collect or harvest any information or data obtained from any “Or - The
        Competition Platform” or Our systems or attempt to decipher any
        transmissions to or from the servers running any service for the “Or -
        The Competition Platform” including attempt to sale, resale, broker,
        re-broker, reverse-engineer or make derivative works of such data. Links
        to Other Sites This Service may contain links to other sites. If you
        click on a third-party link, you will be directed to that site. Note
        that these external sites are not operated by us. Therefore, we strongly
        advise you to review the Privacy Policy of these websites. We have no
        control over and assume no responsibility for the content on these
        third-party sites/applications. Children’s Privacy These Services are
        not for any child under the age of 13. We do not knowingly collect
        personally identifiable information from children under 13. In case we
        discover that a child under 13 has provided us with personal
        information, we will immediately delete this information from our
        servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact the
        grievance officer whose details are provided in the last section of
        these Terms. Advertisements, Third Party Sites and Services Some of the
        “Or - The Competition Platform” Services are supported by advertising
        revenue and may display advertisements, promotions, Sponsorships, and
        links to third-party websites. You hereby agree that “Or - The
        Competition Platform” may place such advertising and promotions on the
        “Or - The Competition Platform” Services or on, about, or in conjunction
        with your Content. The manner, mode, and extent of such advertising and
        promotions are subject to change without specific notice to you. The
        Service may permit you to link to other websites, services, or resources
        on the Internet, and other websites, services or resources may contain
        links to the Site. When you access third-party websites, you do so at
        your own risk. These other websites are not under “Or - The Competition
        Platform” control, and you acknowledge that “Or - The Competition
        Platform” is not responsible for the content, functions, accuracy,
        legality, appropriateness, or any other aspect of such websites or
        resources. The inclusion of any such link does not imply endorsement by
        “Or - The Competition Platform” or any association with its operators.
        You further acknowledge and agree that “Or - The Competition Platform”
        shall not be responsible, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such Content, goods, or services available on or through
        any such website or resource.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">COINS & REWARDS</h3>

      <p className="text-justify py-3.5 px-8">
        The platform is used by users to rewards End Users of the “Or - The
        Competition Platform” through virtual gifts. The winners can redeem
        virtual gifts into cash as per the terms of the platform. The user can
        purchase “OR coins” which will be stored in purchase wallet. There are
        several kinds of “virtual gifts/ emoji” are available on the platform.
        These virtual gifts/ emoji can be given to participants of the
        competition as reward from the side of the non-participant users. The
        participants who receive virtual gifts/ emoji can convert these virtual
        gifts/ emoji into “OR coins” and these “OR coins” can be kept redeem
        wallet as soon as available by platform. These “OR coins” can be
        converted in to cash by the participants/ Winners. The “OR coins”
        purchased cannot be redeemed or refunded. However, the “OR coins”
        converted from virtual gifts/ emoji can be redeemed into cash. These “OR
        coins” are notional tokens and do not represent any real money, value or
        currency. Similarly, “OR coins” are not pre-paid or pre-loaded in any
        other manner with money or currency, and should not be considered
        equivalent to money. The Company may, at its discretion, place limits or
        ceilings on the maximum number of “OR coins” Participants can hold,
        receive or accumulate at a given time, and/or the amount of time for
        which such “OR coins” may be stored, used or redeemed. The Company will
        inform End Users about such limits/ceilings or expiration period through
        periodic in-app notifications. Any “OR coins” received or collected by
        Participants in excess of such limit/ ceiling or expiration period, will
        lapse automatically and will not be recoverable thereafter. An
        Participants may use accumulated “OR coins” for participating in certain
        activities within the Or Platform. Alternatively, Participants may use
        these Or Coins to receive digital currencies provided by certain rewards
        partners (discussed below). The Company has collaborated with
        third-party rewards partners (each a “Rewards Partner”) to enable
        Participants to receive digital currencies in exchange for or coins.
        These Rewards Partners are authorised payment system operators and
        operate digital payment systems in India. The Company may add or remove
        any Rewards Partner from the Platform at its discretion and will inform
        Participants about the Rewards Partners currently onboarded on the
        Platform through periodic in-app notifications. Should Participants
        choose to use his/her OR Coins for this purpose, the End User’s
        activities would also be governed by the terms and conditions and
        privacy policies of the concerned Rewards Partner. We urge each
        Participants to refer, read and ensure compliance with such terms and
        conditions and policies at all times. The Company does not control and
        disclaims any liability for the Participants ’s activities on the
        Rewards Partner’s platform and his/her use of such digital currency. The
        Company may, at its discretion, alter or vary the number of OR Coins
        required for a paid activity with the Platform. Similarly, the Company
        may vary, at its discretion, the amount of digital currencies that may
        be recovered by an Participants in exchange for a OR Coin. To further
        clarify, the Or Coins do not represent a fixed amount of real, digital
        or virtual money or value. The Company does not guarantee that
        Participants will be able to redeem or recover any payment or currency
        against his/her OR Coins at any point in time. The Company may inform
        Participants about these matters and any change to these policies
        through periodic in-app notifications.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">Partner Apps</h3>

      <p className="text-justify py-3.5 px-8">
        To successfully operate this platform, we may sometimes require support
        of partner apps and platforms. The platforms are independent service
        providers and are not property of this platform. We don’t control any
        such partner app and therefore we not accountable for any right or
        liability arising out of the use of such partner apps and platforms. You
        may choose avoid or use partner apps and platforms by your own consent.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">Payments Gateway</h3>
      <p className="text-justify py-3.5 px-8">
        In case of monetary transaction on our platform including but not
        limited to buy tokens, coins, virtual gifts or to redeem any such
        tokens, coins, virtual gifts you permit us to deduct the payment gateway
        charges as applicable by the payment gateway platform.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        Warranty Disclaimer
      </h3>
      <p className="text-justify py-3.5 px-8">
        Any implied warranties including those prescribed by statute are
        expressly disclaimed. To the maximum extent permitted by law, the “or -
        the competition platform” is provided to you “as is,” with all faults,
        without warranty of any kind, without performance assurances or
        guarantees of any kind, and your use is at your sole risk. The entire
        risk of satisfactory quality and performance resides with you. Company,
        company’s licensors, and device partners do not make, and hereby
        disclaim, any and all express, implied, or statutory warranties,
        including implied warranties of condition, uninterrupted use, accuracy
        of data (including but not limited to location data), merchantability,
        satisfactory quality, fitness for a particular purpose, non-infringement
        of third-party rights, and warranties (if any) arising from a course of
        dealing, usage, or trade practice. Company, company’s licensors,
        affiliates, and device partners do not warrant against interference with
        your enjoyment of the platform; will meet your requirements; that the
        “or - the competition platform” will be uninterrupted or error-free, or
        that the “or - the competition platform” will interoperate or be
        compatible with any other service or that any errors in the “or - the
        competition platform” will be corrected. No oral or written advice
        provided by the company, its affiliates company’s licensors and device
        partners, or any authorized representative shall create a warranty. Some
        jurisdictions do not allow the exclusion of or limitations on implied
        warranties or the limitations on the applicable statutory rights of a
        consumer, so some or all of the above exclusions and limitations apply
        only to the fullest extent permitted by law in the applicable
        jurisdiction. Our Platform is an intermediary platform under the
        infotech laws. We allow users to access and use content and contribute
        content while exercising diligence to the extent required under the
        applicable infotech laws. We do not refer, endorse, recommend, verify,
        evaluate or guarantee any actions, outcome, information in connection
        with this “or - the competition platform” or the content or materials,
        nor do we warrant the validity, accuracy, completeness, safety,
        legality, quality, or applicability of anything said, displayed,
        promoted or provided for in this proposal platform, as it is intended
        solely for entertainment purposes. The user is responsible for his/ her
        own decisions and actions undertaken including while providing any
        materials
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">Refund Policy</h3>

      <p className="text-justify py-3.5 px-8">
        1. No Refund Policy Ocre8 Technologies Pvt Limited (“OR Competition”,
        “Ocre8” “company” “We”, “Our”, “Us”) is committed to provide its
        valuable services to its user “You”,“Your”,“User”) through its platform
        Or competition “ÖR competition” mobile application/ website. You agree
        that Your use of “ÖR” mobile application (“App”)/ Website implies your
        understanding regarding the refund policy of the company. Ocre8
        Technologies Pvt Limited for its platform ÖR competition follows a Zero
        refund policy. The Or coins purchased by the users to buy virtual
        gifts/emoji from the platform cannot be refunded. They can only be
        utilized on the platform for the permitted purposes. Your use of the
        website implies your consent that in no case user can demand refund from
        the company. Any request made in this regard shall be deemed reject and
        the company is not under obligation to respond any such requests. If the
        user uses a third-party service through our platform the user have right
        to approach the third to seek refund directly to any such third party.
        We have no control over such transactions therefore we are not
        responsible for any demand raised for refund to any third party.
      </p>

      <h3 className="text-2xl text-black mt-10  font-bold">
        LIMITATION OF LIABILITY
      </h3>
      <p className="text-justify py-3.5 px-8">
        To the fullest extent permitted by law, in no event shall company (nor
        its directors, employees, agents, sponsors, partners, suppliers, content
        providers, licensors or resellers,) be liable under contract, tort,
        strict liability, negligence or any other legal or equitable theory with
        respect to the service: (i) for any lost profits, data loss, loss of
        goodwill or opportunity, or special, indirect, incidental, punitive, or
        consequential damages of any kind whatsoever; (ii) for your reliance on
        the service; (iv) for any matter beyond its or their reasonable control,
        even if company has been advised of the possibility of any of the
        aforementioned damages.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        CHANGES TO THESE TERMS
      </h3>

      <p className="text-justify py-3.5 px-8">
        We may amend or update these Terms. We will provide you notice of
        amendments to these Terms, as appropriate, and update the “Last
        Modified” date at the top of these Terms. Your continued use of “Or –
        The Competition Platform” confirms your acceptance of these Terms, as
        amended. If you do not agree to the new terms, you are no longer
        authorized to use “Or – The Competition Platform”. You are expected to
        check Terms frequently so you are aware of any changes, as they are
        binding on you. These changes are effective immediately after they are
        posted on this page.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        Dispute Resolution:
      </h3>
      <p className="text-justify py-3.5 px-8">
        In case of any dispute arising between the parties. Any of the two party
        may refer such dispute for final resolution by arbitration under the
        Arbitration and Conciliation Act 1996. The Tribunal shall consist of
        three (3) arbitrators. Each party shall appoint 1 (one) arbitrator and
        the two arbitrators shall appoint the third arbitrator. The arbitration
        proceedings shall be held at Nagpur, Maharashtra, India. The language to
        be used in the arbitral proceedings shall be English.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">Force Majeure.</h3>
      <p className="text-justify py-3.5 px-8">
        Notwithstanding any other term or provision of this Agreement to the
        contrary, a party hereto shall not be in violation or breach of an
        obligation hereunder if and to the extent that it fails to perform or
        satisfy such obligation by reason of a strike, walk out or other labor
        interruption or disturbance, riot, fire, explosion, war, armed conflict,
        governmental action, storm, flood, act of God or any similar cause or
        event which is beyond the control of such party (a “Force Majeure”);
        provided, however, that such party shall perform or satisfy such
        obligation as soon as reasonably practicable after such Force Majeure
        ends or is diminished to the extent reasonably necessary for such party
        to perform or satisfy such obligation and, provided further, that the
        other party shall have the right to terminate this Agreement if the
        party to perform is unable to perform or satisfy such obligations within
        60 days after the occurrence of such Force Majeure.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">Governing Law</h3>
      <p className="text-justify py-3.5 px-8">
        These Terms shall be governed and construed under the laws of India,
        Maharashtra, which governing law applies to an agreement without regard
        to its conflict of law provisions.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        Devices and Software
      </h3>
      <p className="text-justify py-3.5 px-8">
        You must provide certain devices, software, and data connections to use
        our Services, which we otherwise do not supply. For as long as you use
        our Services, you consent to downloading and installing updates to our
        Services, including automatically.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        Device Storage Permission
      </h3>
      <p className="text-justify py-3.5 px-8">
        You provide us storage permission (Read and write) by which we can
        upload your content to our server and display it to users. You confirm
        you are authorized to provide us such permissions to allow us to offer
        our Services.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">
        Grievance Redressal
      </h3>
      <p className="text-justify py-3.5 px-8">
        If you wish to complain about our Services / the use of our services by
        others, please contact our Resident Grievance Officer, whose details are
        below: Email ID: info@ocre8technologies.com Phone number: +91 7744858786
        We will endeavour to redress the complaint within one month from the
        date of receipt of the complaint. For claims related to copyright
        infringement, please see our report copyright infringement page at
        copyright.
      </p>
      <h3 className="text-2xl text-black mt-10  font-bold">Contact Us</h3>
      <p className="text-justify py-3.5 px-8">
        In case of any questions and/or feedback regarding the user agreement,
        contact us at support info@ocre8technologies.com
      </p>
    </div>
  );
};
<br />;
