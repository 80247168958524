import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
export const FooterSection = () => {
  return (
    <div className="bg-gradient-to-b  from-red  to-black1  bottom-0  ">
      <footer className="flex flex-col items-center  justify-center p-3.5 ">
        <div className="flex  items-center justify-between">
          <a
            href="https://www.facebook.com/competition-platform-106836668249059/"
            className="m-4"
          >
            <FontAwesomeIcon size="lg" icon={faFacebook} className="" />
          </a>
          <a
            href="https://www.linkedin.com/company/ocre8-technologies-pvt-ltd/"
            className="m-4"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>

          <a href="https://www.instagram.com/or_platform/" className="m-4">
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
          <a href="https://twitter.com/OrPlatform" className="m-4">
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
        </div>
        <div className=" text-white text-sm  flex flex-col items-center justify-center text-center">
          <div>
            <h3>
              &copy; Copyright @ 2021-2022. Ocre8 Technologies Private Limited.
              All Right Reserved
            </h3>
          </div>
          <div className="py-3.5">
            <Link to="/PrivacyPolicy">Privacy Policy</Link>
            <Link to="/Termsandcondition"> Terms and conditions </Link>
            <Link to="/RulesforCompetition"> Rules for Competiton </Link>
            <Link to="/FAQ's">FAQ's</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};
