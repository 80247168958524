import React from "react";
import Logo from "../Assests/Images/logomain.png";

export const Header = () => {
  return (
    <nav className="py-3.5  sm:max-w-2xl ">
      <div className="py-1.5 flex items-center ">
        <a href="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-25 w-12 ml-7 " />
        </a>
      </div>
    </nav>
  );
};
