import React from "react";
import BannerReponsive from "../Assests/Images/bannernew2.png";
export const BannerSection = () => {
  return (
    <div className=" flex flex-col w-full h-15  justify-center items-center   ">
      <div className=" object-contain   bg-yellow-900">
        <img src={BannerReponsive} alt="banner" className="object-contain" />
      </div>
    </div>
  );
};
