import React from "react";
import Main from "../Assests/Images/Or-mockupfinal.png";

export const AboutSection = () => {
  return (
    <div className="flex-col items-center justify-evenly   xl:flex xl:flex-row xl:h-screen   ">
      <div className="h-full py-3.5 px-3.5 text-center sm:px-4   xl:h-5/6 ">
        <h1 className="text-6xl tracking-wide z-50 text-transparent uppercase bg-clip-text bg-gradient-to-r from-secondary to-tertiary  font-rubik leading-tighter font-extrabold mt-4 sm:text-7xl xl:text-7xl">
          Create <br /> your <br /> Own <br />{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-b  from-red  to-black1">
            Identity
          </span>
        </h1>
      </div>
      <div className="flex items-center opacity-0 flex-col justify-center  mt-10  ">
        <img src={Main} alt="Phone" className="sm:max-h-18px  sm:w-6/12" />
      </div>
    </div>
  );
};
